<template>
	<div class="wrap">
		<div class="header">申请开通在线支付</div>
		<div class="news-wrap">
			<el-form label-width="150px" :model="form" ref="form" :rules="rules" :validate-on-rule-change="false">
				<el-form-item label="营业执照(副本)照片" prop="license">
					<el-upload class="avatar-uploader" :action="picUrl"
						v-loading.fullscreen.lock="loading" element-loading-text="拼命加载中……" :on-progress="changezz"
						:before-upload="beforeAvatarUploadzz"
						element-loading-background="rgba(250, 250, 250, 0.3)"
						:show-file-list="false" :data="fileInfo2" :on-success="handleAvatarSuccess"
						accept="image/jpeg,image/png">
						<img v-if="form.imageUrl" :src="form.imageUrl" :onerror="imgSrc" class="avatar">
						<i v-else class="el-icon-plus avatar-uploader-icon"></i>
					</el-upload>
					<div class="tishinews">
						小提示：上传图片只能是JPG/PNG格式且上传图片大小不能超过 5MB！
					</div>
					<div class="ts"></div>
					<div class="text">
						<ul class="textul">
							<li>要求：</li>
							<li>营业执照（副本）四边角需清晰，信息完整，不能遮挡/涂抹/修改</li>
							<li>营业执照（副本）如为复印件则必须加盖公章</li>
							<li>营业执照（副本）图片需清晰</li>
							<li>图片不可添加与备案信息无关的水印</li>
							<li>必须为彩色扫描件或数码照片</li>
							<li>必须为中国大陆工商局颁发</li>
							<li>副本必须在有效期内，且带有最新年检章(当前成立的公司可以无年检章)</li>
						</ul>
					</div>
				</el-form-item>
				<input type="hidden" v-model="form.license" value=""  prop="license" >
				<input type="hidden" v-model="form.licensename" value="">
				<input type="hidden" v-model="form.faren_cphoto_zheng" value="" prop="faren_cphoto_zheng">
				<input type="hidden" v-model="form.faren_cphoto_fan" value="" prop="faren_cphoto_fan">


				<input type="hidden" v-model="form.Address" value="">
				<!-- onkeyup="value=value.replace(/[^\u4E00-\u9FA5]/g,'')" @keyup="upInput"  -->
				<el-form-item label="店铺简称" class="abbre" prop="abbre">
					<!-- oninput="if(value.length>6)value=value.slice(0,6)else if(value.length<2)value=value"-->
					<!-- oninput="if(value.length>6)value=value.slice(0,6)" onchange ="value=value.replace(/[^\u4E00-\u9FA5]/g,'')" -->
					<el-input v-model="form.abbre" type='required' class="inputOn" maxlength="6" @blur="descInput"
						placeholder="请输入2-6个汉字" /><!-- oninput="if(value.length>6)value=value.slice(0,6)"  -->
					<!-- <el-input v-model="form.abbre"  type='required' class="inputOn" maxlength="6"  oninput="value=value.replace(/[^\u4E00-\u9FA5]{2,6}$/g,'')" show-word-limit   placeholder="请输入2-6个汉字" /> -->

				</el-form-item>
				<el-form-item label="公司名称" prop="CompanyName">
					<el-input v-model="form.CompanyName" placeholder="请填写公司名称" />
				</el-form-item>
				<el-form-item label="统一社会信用代码" prop="CompanyCertNo">
					<el-input v-model.trim="form.CompanyCertNo" placeholder="请填写统一社会信用代码"
						@keydown.native="keydown($event)"/>
				</el-form-item>
				<div class="a">
					<div class="card">
						<el-form-item label="法人身份证人像面" prop="faren_cphoto_zheng">
							<el-upload class="avatar-uploader" :action="picUrl"
								v-loading.fullscreen.lock="loadingA" element-loading-text="拼命加载中……"
								:on-progress="changeA" :before-upload="beforeAvatarUploadA"
								element-loading-background="rgba(250, 250, 250, 0.5)" :show-file-list="false"
								:data="fileInfo" :on-success="handleCardA" accept="image/jpeg,image/png">
								<img v-if="form.imageUrlA" :src="form.imageUrlA" :onerror="imgSrc" class="avatar">
								<i v-else class="el-icon-plus avatar-uploader-icon"></i>
							</el-upload>
						</el-form-item>
						<el-form-item label="法人身份证国徽面" prop="faren_cphoto_fan">
							<el-upload class="avatar-uploader" :action="picUrl"
								v-loading.fullscreen.lock="loadingB" element-loading-text="拼命加载中……"
								:on-progress="changeB" :before-upload="beforeAvatarUploadB"
								element-loading-background="rgba(250, 250, 250, 0.5)" :show-file-list="false"
								:data="fileInfo" :on-success="handleCardB" accept="image/jpeg,image/png">
								<img  v-if="form.imageUrlB" :src="form.imageUrlB" :onerror="imgSrc" class="avatar">
								<i v-else class="el-icon-plus avatar-uploader-icon"></i>
							</el-upload>
							
						</el-form-item>
					</div>
					<div class="red">注：请上传法人身份证照片</div>
					<div class="tishinewsSfz">
						小提示：上传图片只能是JPG/PNG格式且上传图片大小不能超过 5MB！
					</div>
					
				</div>
				<el-form-item label="法人姓名" prop="faren_name">
					<el-input v-model="form.faren_name" placeholder="请填写法人姓名" />
				</el-form-item>
				<el-form-item label="身份证号码" prop="faren_cpcode">
					<el-input v-model.trim="form.faren_cpcode" @keydown.native="keydown($event)" placeholder="请填写法人身份证号" />
				</el-form-item>
				<el-form-item label="银行开户许可证" prop="account_licence">
					<el-upload class="avatar-uploader" :action="picUrl"
						v-loading.fullscreen.lock="loadingyh" element-loading-text="拼命加载中……" :on-progress="changeyh"
						element-loading-background="rgba(250, 250, 250, 0.3)" :show-file-list="false" :data="fileInfo3"
						:before-upload="beforeAvatarUploadyh"
						:on-success="handsuccessyh" accept="image/jpeg,image/png">
						<img v-if="form.imageUrlyh" :src="form.imageUrlyh" :onerror="imgSrc" class="avatar">
						<i v-else class="el-icon-plus avatar-uploader-icon"></i>
					</el-upload>
					<div class="tishinews">
						小提示：上传图片只能是JPG/PNG格式且上传图片大小不能超过 5MB！
					</div>
				</el-form-item>
				<div class="red">
					注：请上传开户许可证 原件照片或复印件加盖公章  
				</div>
				<el-form-item label="企业开户银行" prop="Account">
					<el-select v-model="form.BankName" filterable placeholder="请选择" style="width: 319px;" @change="chengebanck($event)">
						<el-option v-for="item in bancks" :key="item.code" :label="item.name" :value="{'name':item.name,'code':item.code}">
							
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="开户行网点" prop="BankName">
					<el-input v-model="form.BankAddress" placeholder="" />
				</el-form-item>
				<el-form-item label="银行卡号" prop="BankAddress">
					<el-input v-model.trim="form.Account" placeholder="" @keydown.native="keydown($event)"/>
				</el-form-item>
				<el-form-item label="预留手机号" prop="MobilePhone">
					<el-input v-model.trim="form.MobilePhone"  placeholder="" @keydown.native="keydown($event)"/>
				</el-form-item>
				<el-form-item label="支持开票类型" prop="InvoiceType">
					<el-radio v-model="form.InvoiceType" label="1">普通发票</el-radio>
					<el-radio v-model="form.InvoiceType" label="2">增值税发票</el-radio>
				</el-form-item>
				<div class="bot">
					<el-checkbox v-model="checked" checked="checked">
						<span class="bot_zs">同意并遵守</span>
						<a class="blue" target="_blank" :href="$store.state.HIMG_URL+'/xiaodu.pdf'">《消毒设备网支付服务协议》</a>
					</el-checkbox>

				</div>
				<div class="btn_box">
					<el-button type="primary" class="btnn" @click="next" :loading="loadingtj" >提交</el-button>
				</div>

			</el-form>
		</div>
		<div>

		</div>

	</div>
</template>

<script>
	import {
		banksdata
	} from '@/assets/js/bank.js'
	export default {
		data() {
			return {
				enter: 0,
				imgSrc: 'this.src="' + require('../../assets/img/mr.jpg') + '"',
				loading:false,
				loadingA: false,
				loadingB: false,
				loadingyh: false,
				loadingtj:false,
				fileInfo: {
					type: "Member",
					zhengjian: "1" //身份证
				},
				fileInfo2: {
					type: "Member",
					zhengjian: "2" //营业执照
				},
				fileInfo3:{
					type: "Member",
					zhengjian: "3" //银行许可证
				},
				bancks: [],
				checked: '',
				data_id: '',
				form: {
					abbre: '',
					faren_name: '',
					faren_cpcode: '',
					CompanyName: '',
					CompanyCertNo: '',
					Account: '',
					BankName: '',
					BankAddress: '',
					MobilePhone: '',
					Address: '',
					MerMobilePhone: '',
					InvoiceType: '1',
					imageUrl: '',
					imageUrlA: '',
					imageUrlB: '',
					imageUrlyh: '',
					account_licence: '',
					faren_cphoto_zheng: "",
					faren_cphoto_fan: "",
					license:"",
				},
				peoproFrY: '', //营业执照
				peoproFr: '', //法人
				rules: {
					license: [{
						required: true,
						message: '请上传营业执照（副本）'
					}],
					faren_cphoto_zheng: [{
						required: true,
						message: '请上传身份证正面'
					}],
					faren_cphoto_fan:[{
						required: true,
						message: '请上传身份证反面'
					}],
					account_licence:[{
						required: true,
						message: '请上传银行开户许可证'
					}],
					abbre: [{
						required: true,
						message: '请输入店铺简称',
						trigger: 'blur'
					}],
					/* 验证非空 */
					CompanyName: [{
						required: true,
						message: '请输入公司名称',
						trigger: 'blur|change'
					}],
					CompanyCertNo: [{
						required: true,
						message: '请填写统一社会信用代码',
						trigger: 'blur|change'
					}],
					faren_name: [{
						required: true,
						message: '请输入法人姓名',
						trigger: 'blur|change'
					}],
					faren_cpcode: [{
						required: true,
						message: '请填写身份证号码',
						trigger: 'blur|change'
					}],
					BankName: [{
						required: true,
						message: '请填写开户行',
						trigger: 'blur|change'
					}],
					BankAddress: [{
						required: true,
						message: '请填写开户网点',
						trigger: 'blur|change'
					}],
					Account: [{
						required: true,
						message: '请填写对公账号',
						trigger: 'blur|change'
					}],
					MobilePhone: [{
						required: true,
						message: '请填写预留手机号',
						trigger: 'blur|change'
					}]
				},
				picUrl:''
			}
		},
		created() {
			this.$axios.get('/applyForOpeningTransaction?seller_id=' + this.$store.state.SELLER_ID).then(data => {
				let h = 'https://img.chinaxdsb.com/'
				this.form.CompanyName = data.CompanyName,
					this.form.CompanyCertNo = data.CompanyCertNo,
					this.form.faren_name = data.faren_name, //法人
					this.form.faren_cpcode = data.faren_cpcode, //注册shenfenzhneg 
					this.form.BankName = data.BankName,
					this.form.BankAddress = data.BankAddress,
					this.form.Account = data.Account, //
					this.form.MobilePhone = data.MobilePhone,
					this.form.imageUrl =  data.license ? h + data.license :'',
					this.form.imageUrlA = data.faren_cphoto_zheng ? h + data.faren_cphoto_zheng :'',
					this.form.imageUrlB = data.faren_cphoto_fan? h + data.faren_cphoto_fan :'',
					this.form.imageUrlyh = data.account_licence ? h + data.account_licence :'',
					this.form.license = data.license,
					this.form.faren_cphoto_zheng = data.faren_cphoto_zheng,
					this.form.faren_cphoto_fan = data.faren_cphoto_fan,
					this.form.account_licence = data.account_licence,
					this.form.Address = data.Address,
					this.form.abbre = data.abbre,//店铺简称
					this.form.bankNum = data.bankNum
			})
			this.$axios.get('https://www.chinaxdsb.com/bankAllTitle', {
				id: this.$store.state.SELLER_ID,
			}).then(data => {
				data.forEach((val,id) =>{
					this.bancks.push(val)
				})
			})
			this.picUrl = this.$store.state.BASE_URL+'/uploadImgOne.html'
		},
		methods: {
			// 禁止空格
			 keydown(e){
			      if(e.keyCode == 32){
			        e.returnValue = false
			      }
			    },
			//改变
			chengebanck(event){
				this.form.BankName = event.name
				this.form.bankNum = event.code
			},
			//提交
			next() {
				this.loadingtj = true;
				var parems = {
					seller_id: this.$store.state.SELLER_ID,
					faren_name: this.form.faren_name, //负责人姓名
					faren_cpcode:this.form.faren_cpcode, //负责人身份证号
					CompanyName: this.form.CompanyName, //企业名称
					CompanyCertNo: this.form.CompanyCertNo, //企业编号 通一
					Account: this.form.Account, //收款账号
					BankName: this.form.BankName, //收款账户名
					BankAddress: this.form.BankAddress, //开户行
					MobilePhone: this.form.MobilePhone, //预留手机号
					Address: this.form.Address, //营业执照地址
					MerMobilePhone:this.form.MobilePhone, //负责人电话
					InvoiceType: this.form.InvoiceType,
					license: this.form.license,
					faren_cphoto_zheng: this.form.faren_cphoto_zheng,
					faren_cphoto_fan: this.form.faren_cphoto_fan,
					account_licence: this.form.account_licence,
					//abbre:$(".abbre").siblings("div").children("input").val() //店铺简称
					abbre: this.form.abbre ,//店铺简称
					bankNum:this.form.bankNum
				}
				this.$refs.form.validate(valid => {
					 if (this.form.license && this.form.faren_cphoto_zheng && this.form.faren_cphoto_fan && this.form.abbre && this.form.account_licence) {
						this.$axios.post('applyForOpeningTransaction', parems).then(res => {
							if (res.ReturnCode == '0000') {
								this.loadingtj = false;
								this.$message({
									message: res.ReturnMsg,
									type: 'success'
								})
								this.$router.push('/auszahlung/check');
							} else {
								this.loadingtj = false;
								// this.$message({
								// 	message: res.ReturnMsg,
								// 	type: 'error'
								// })
							 this.$alert(res.ReturnMsg, '提示', {
							  type: 'warning',
							  dangerouslyUseHTMLString: true
							});
								
							}
						})
					} else {
						this.loadingtj = false;
						this.$message.error('请补全必填项')
						return false
					}
				})
			},
			changezz(event, file, fileList) {
				this.loading = true;
			},
			handleAvatarSuccess(res, file) {
				this.handleAvatar(res, file,1)
				this.loading = false;
			},
			beforeAvatarUploadzz(file) {
				const isJPG = file.type === 'image/jpeg';
				const isPNG = file.type === 'image/png';
				const isLt5M = file.size / 1024 / 1024 < 5;
				if (!isJPG && !isPNG) {
					this.$message.error('上传图片只能是 JPG/PNG 格式!');
				}
				if (!isLt5M) {
					this.$message.error('上传图片大小不能超过 5MB!');
				}
				return (isJPG || isPNG) && isLt5M;
			},
			changeA(event, file, fileList) {
				this.loadingA = true;
			},
			handleCardA(res, file) {
				this.handleAvatar(res, file,2)
				this.loadingA = false;
			},
			beforeAvatarUploadA(file) {
				const isJPG = file.type === 'image/jpeg';
				const isPNG = file.type === 'image/png';
				const isLt5M = file.size / 1024 / 1024 < 5;
				if (!isJPG && !isPNG) {
					this.$message.error('上传图片只能是 JPG/PNG 格式!');
				}
				if (!isLt5M) {
					this.$message.error('上传图片大小不能超过 5MB!');
				}
				return (isJPG || isPNG) && isLt5M;
			},
			changeB(event, file, fileList) {
				this.loadingB = true;
			},
			handleCardB(res, file) {
				this.handleAvatar(res, file,3)
				this.loadingB = false;
			},
			beforeAvatarUploadB(file) {
				const isJPG = file.type === 'image/jpeg';
				const isPNG = file.type === 'image/png';
				const isLt5M = file.size / 1024 / 1024 < 5;
				if (!isJPG && !isPNG) {
					this.$message.error('上传图片只能是 JPG/PNG 格式!');
				}
				if (!isLt5M) {
					this.$message.error('上传图片大小不能超过 5MB!');
				}
				return (isJPG || isPNG) && isLt5M;
			},
			changeyh(event, file, fileList) {
				this.loadingyh = true;
			},
			handsuccessyh(res, file) {
				this.handleAvatar(res, file,4)
				this.loadingyh = false;
			},
			beforeAvatarUploadyh(file) {
				const isJPG = file.type === 'image/jpeg';
				const isPNG = file.type === 'image/png';
				const isLt5M = file.size / 1024 / 1024 < 5;
				if (!isJPG && !isPNG) {
					this.$message.error('上传图片只能是 JPG/PNG 格式!');
				}
				if (!isLt5M) {
					this.$message.error('上传图片大小不能超过 5MB!');
				}
				return (isJPG || isPNG) && isLt5M;
			},
			descInput() {
				var reg = /^[\u4E00-\u9FA5]/g;
				var inputVal = $(".inputOn input").val()
				if (inputVal.length < 2) {
					this.$message.error("至少输入2个汉字")
				} else if (reg.test(inputVal) == 'false') {
					this.$message.error("请填写汉字")
				}
			}
		}
	}
</script>

<style scoped>
	.wrap{
		width: 1600px;
		background-color: #fff;
	}
	.tishinewsSfz{
		color: #666ee8;
		margin-left: 150px;
		height: 50px;
		line-height: 30px;
		font-size: 14px;
	}
	.red {
		color: red;
		margin-left: 150px;
		height: 50px;
		line-height: 30px;
	}

	.header {
		padding: 0 28px;
		height: 77px;
		border-bottom: 1px solid #e0e0e0;
		display: flex;
		font-size: 18px;
		color: #000000;
		align-items: center;
		font-weight: 700;
	}

	.header::before {
		content: '';
		width: 4px;
		height: 20px;
		background: #0e69db;
		margin-right: 10px;
	}

	/*  */
	.el-form {
		padding: 20px;
		max-width: 1020px;
	}

	.news-wrap>>>.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.news-wrap>>>.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.news-wrap .avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		line-height: 178px;
		text-align: center;
	}

	.news-wrap .avatar {
		width: 178px;
		height: 178px;
		display: block;
	}

	.submit-wrap {
		padding-top: 30px;
		width: 155px;
		height: 40px;
		line-height: 40px;
	}

	.bot_zs {
		color: #666;
	}

	.blue {
		color: #1989fa;
	}

	.bot {
		display: flex;
		justify-content: center;
		margin: 30px 0;

	}

	.btn_box {
		display: flex;
		justify-content: center;
	}

	.btnn {
		width: 155px;
		height: 40px;
		margin-top: 30px;
	}

	.next {
		width: 300px;
		height: 40px;
	}

	.n {
		display: flex;
		justify-content: center;
	}

	.card {
		display: flex;
	}

	.textul {
		color: #666;
		line-height: 24px;
	}

	a {
		text-decoration: none;
	}
	/* 下拉框颜色 */
	.el-select-dropdown__item.selected{
		color: #8C939D !important;
	}
	.tishinews{
		color: #666ee8;
	}
</style>
